import React from "react";
import { useRecoilState } from "recoil";
import { optionsAtom } from "./atoms";

const Options = () => {
  const [options, setOptions] = useRecoilState(optionsAtom);
  const onCheckBoxChange = (optionId) => {
    setOptions(
      options.map((o) => {
        if (optionId === o.id) {
          const updatedOption = { ...o };
          updatedOption.use = !o.use;
          return updatedOption;
        } else {
          return o;
        }
      })
    );
  };
  const createCheckBoxes = () => {
    return options.map((o) => {
      return (
        <div className='form-check my-3' key={o.id}>
          <input
            className='form-check-input'
            type='checkbox'
            checked={o.use}
            id={o.id}
            onChange={() => onCheckBoxChange(o.id)}
          ></input>
          <label className='form-check-label' htmlFor={o.id}>
            {o.name}
          </label>
        </div>
      );
    });
  };

  return <>{createCheckBoxes()}</>;
};

export default Options;
