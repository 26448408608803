import { useRecoilValue } from "recoil";
import { generatedPasswordAtom } from "./atoms";

const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
};

const GeneratedPasswordField = () => {
  const generatedPassword = useRecoilValue(generatedPasswordAtom);
  const onCopyButtonClick = (e) => {
    e.preventDefault();
    copyTextToClipboard(generatedPassword);
  };
  return (
    <>
      <label htmlFor='generatedPassword' className='form-label'>
        Password
      </label>
      <div className='input-group mb-3'>
        <input
          id='generatedPassword'
          type='text'
          className='form-control'
          aria-describedby='button-addon2'
          value={generatedPassword}
          readOnly={true}
        ></input>
        <button
          className='btn btn-light btn-outline-secondary'
          type='button'
          id='button-addon2'
          onClick={onCopyButtonClick}
        >
          Copy
        </button>
      </div>
    </>
  );
};

export default GeneratedPasswordField;
