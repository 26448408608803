import { atom } from "recoil";

const defaultOptions = [
  {
    id: "numbers",
    name: "Include Numbers",
    data: "0123456789".split(""),
    use: true,
    excludes: false,
  },
  {
    id: "lower",
    name: "Include Lowercase Letters",
    data: "abcdefghijklmnopqrstuvwxyz".split(""),
    use: true,
    excludes: false,
  },
  {
    id: "upper",
    name: "Include Uppercase Letters",
    data: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
    use: true,
    excludes: false,
  },
  {
    id: "symbols",
    name: "Include Symbols < ( [ { \\ ^ - = $ ! | ] } ) ? * + . >",
    data: "<([{\\^-=$!|]})?*+.>".split(""),
    use: false,
    excludes: false,
  },
  {
    id: "similar",
    name: "Exclude Similar Characters ( i, l, 1, L, o, 0, O )",
    data: "il1Lo0O".split(""),
    use: false,
    excludes: true,
  },
];

export const optionsAtom = atom({
  key: "options",
  default: defaultOptions,
});

export const passwordLengthAtom = atom({
  key: "passwordLength",
  default: 20,
});

export const generatedPasswordAtom = atom({
  key: "generatedPassword",
  default: "",
});
