import "./App.css";
import { RecoilRoot } from "recoil";

import Options from "./Options";
import PasswordLength from "./PasswordLength";
import GeneratedPasswordField from "./GeneratedPasswordField";
import GenerateButton from "./GenerateButton";

function App() {
  return (
    <RecoilRoot>
      <div className='App'>
        <form className='p-3'>
          <div className='row'>
            <div className='col-md-6'>
              <PasswordLength />
              <Options />
            </div>

            <div className='col-md-6'>
              <GeneratedPasswordField />
            </div>
          </div>
          <div className='col-12'></div>
          <GenerateButton />
        </form>
      </div>
    </RecoilRoot>
  );
}

export default App;
