import React from "react";
import { useRecoilState } from "recoil";
import { passwordLengthAtom } from "./atoms";

const MIN_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 128;

const PasswordLength = () => {
  const [passwordLength, setPasswordLength] = useRecoilState(passwordLengthAtom);
  const onInputChange = (e) => {
    let l = parseInt(e.target.value);
    if (l > MAX_PASSWORD_LENGTH) {
      l = MAX_PASSWORD_LENGTH;
    }
    if (l < MIN_PASSWORD_LENGTH) {
      l = MIN_PASSWORD_LENGTH;
    }
    setPasswordLength(l);
  };
  return (
    <>
      <label htmlFor='passwordLength' className='form-label'>
        Password Length
      </label>
      <div className='row'>
        <div className='col-md-3 col-xl-2'>
          <input
            type='number'
            className='form-control'
            id='passwordLength'
            value={passwordLength}
            onChange={onInputChange}
          ></input>
        </div>
        <div className='col-md-9 col-xl-10 mt-2'>
          <input
            type='range'
            className='form-range'
            min={MIN_PASSWORD_LENGTH}
            max={MAX_PASSWORD_LENGTH}
            value={passwordLength}
            onChange={onInputChange}
          ></input>
        </div>
      </div>
    </>
  );
};

export default PasswordLength;
