import { useRecoilValue, useSetRecoilState } from "recoil";

import { optionsAtom, generatedPasswordAtom, passwordLengthAtom } from "./atoms";
import generatePassword from "./passwordGenerator";

const GenerateButton = () => {
  const options = useRecoilValue(optionsAtom);
  const passwordLength = useRecoilValue(passwordLengthAtom);
  const setGeneratedPassword = useSetRecoilState(generatedPasswordAtom);
  const onButtonClick = (e) => {
    e.preventDefault();
    setGeneratedPassword(generatePassword(options, passwordLength));
  };
  const noIncludesChosen = () => {
    const usedIncludes = options.filter((o) => {
      if (o.excludes) {
        return false;
      }
      if (o.use) {
        return true;
      } else {
        return false;
      }
    });
    if (usedIncludes.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  const showAlert = () => {
    if (noIncludesChosen()) {
      return (
        <div className='alert alert-danger' role='alert'>
          No set of characters selected!
        </div>
      );
    }
  };
  return (
    <>
      {showAlert()}
      <button
        type='submit'
        className='btn btn-primary'
        onClick={onButtonClick}
        disabled={noIncludesChosen()}
      >
        Generate new Password
      </button>
    </>
  );
};

export default GenerateButton;
