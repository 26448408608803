const generatePassword = (options, passwordLength) => {
  const characters = getCharacters(options);
  const passwordCharacters = [...Array(passwordLength).keys()].map(() => {
    return characters[Math.floor(Math.random() * characters.length)];
  });
  return passwordCharacters.join("");
};

const getCharacters = (options) => {
  let characters = [];
  options.forEach((o) => {
    if (!o.use) {
      return;
    }
    if (!o.excludes) {
      characters.push(...o.data);
    } else {
      characters = characters.filter((x) => !o.data.includes(x));
    }
  });
  return characters;
};

export default generatePassword;
